export default {
  data() {
    return {}
  },
  computed:{
    products () {
      return this.$store.getters.getProducts
    },
    selections() {
      return this.$store.getters.getSelections
    },
    rooftile () {
      let product = this.products.find(p => p.type === 'rooftile' && p.series === this.selections.rooftile.value)
      if (!product) return
      let variant = product.variants.find(v => v.treatment == this.selections.rooftileTreatment.value && v.color == this.selections.rooftileColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      return fullProduct
    },
    // allowBigPack() {return this.$store.getters.getSelectedValue('bigpack')},
    maxRoofLength () {
      let roofTilt = this.selections.roofTilt.value
      let snowZone = this.selections.snowZone.value
      let roofType = this.selections.rooftype.value
      let maxCC = this.rooftile.maxDistanceConsole

          let my = 0.8
          let pi = Math.PI

          // PULPETTAK
          if (roofTilt <= 30) {
            my = 0.8
          } else if (roofTilt <= 52.5) {
            my = 0.8 * (60 - roofTilt) / 30
          } else if (roofTilt <= 60) {
            my = 0.2
          } else {
            my = 0
          }
          let resultPulpet = 1200 * 5 /(maxCC * (snowZone * my * Math.cos(pi * roofTilt / 180) * Math.sin(pi * roofTilt / 180)))


          // SADELTAK
          if (roofTilt <= 22.5) {
            my = 0.8 + ((0.3 / 22.5) * roofTilt)
          } else if (roofTilt <= 53.2) {
            my = 1.1 - (0.9 / 30.7 * (roofTilt - 22.5))
          } else {
            my = 0.2
          }
          let resultSadel = 1200 * 5 /(maxCC * (snowZone * my * Math.cos(pi * roofTilt / 180) * Math.sin(pi * roofTilt / 180)))
      
      if (roofType === 'Övriga tak') {
        return resultSadel 
      } else if (roofType === 'Pulpettak') {
          return resultPulpet
      } else {
        return null
      }
    },
    rowsSnowprotection () {
      let rows = Math.ceil(this.selections.roofLength.value / this.maxRoofLength)
      return rows
    },
    distanceConsole () {
      let maxDistance = Math.round(this.maxDistance * 100) / 100
      let ccRooftile = this.rooftile.cc
      return Math.floor(maxDistance / ccRooftile) * ccRooftile
    },
    maxDistance () {
      let roofTilt = this.selections.roofTilt.value
      let snowZone = this.selections.snowZone.value
      let roofLength = this.selections.roofLength.value / this.rowsSnowprotection
      let roofType = this.selections.rooftype.value

          let my = 0.8
          let pi = Math.PI

          // PULPETTAK
          if (roofTilt <= 30) {
            my = 0.8
          } else if (roofTilt <= 52.5) {
            my = 0.8 * (60 - roofTilt) / 30
          } else if (roofTilt <= 60) {
            my = 0.2
          } else {
            my = 0
          }
          // let factorPulpet = my
          let resultPulpet = 5 / (snowZone * my * Math.cos(pi * roofTilt / 180) * Math.sin(pi * roofTilt / 180) * roofLength) * 1200
          if (resultPulpet > 1200) resultPulpet = 1200

          // SADELTAK
          if (roofTilt <= 22.5) {
            my = 0.8 + ((0.3 / 22.5) * roofTilt)
          } else if (roofTilt <= 53.2) {
            my = 1.1 - (0.9 / 30.7 * (roofTilt - 22.5))
          } else {
            my = 0.2
          }
          // let factorSadel = my
          let resultSadel = 5 / (snowZone * my * Math.cos(pi * roofTilt / 180) * Math.sin(pi * roofTilt / 180) * roofLength) * 1200
          if (resultSadel > 1200) resultSadel = 1200
      
      if (roofType === 'Övriga tak') {
        return resultSadel 
      } else if (roofType === 'Pulpettak') {
          return resultPulpet
      } else {
        return null
      }
    },
    selectedSnowProtection() {
      return this.selections.roofSafetyLengths.value.length > 0
    },
    roofMultiplier() {
      let selectedRooftile = this.rooftile.name
      let multiplier 
      switch (selectedRooftile) {
        case "Palema 2-kupig":
        case "Carisma platt":
        case "Exklusiv 1-kupig":
        case "Höganäs 2-kupig":
          multiplier = 4
          break;
        case "Hansa 1-kupig":
        case "Piano platt":
          multiplier = 5
          break;
        case "Tvilling 2-kupig":
          multiplier = 3
          break;
      }
      return multiplier * this.rooftile.cc
    },
  },

methods: {
  amountOfProductsAndJoints(lengthsArr = [], productLength = 1) {
    let total = 0, joints = 0;
    lengthsArr.forEach((distance) => {
      if (!isNaN(Number(distance))) {
        let length = Math.floor(Number(distance) / productLength) * productLength;
        let lengthDiff = distance - length;
        let amount;
        if (lengthDiff > 0.5) {
          amount = Math.floor(Number(distance) / productLength) + 1;
        } else {
          amount = Math.floor(Number(distance) / productLength);
        }
        total += amount;
        joints += Math.max(amount - 1, 0);
      }
    });
    return { products: total, joints: joints };
  },

  amountOfProducts(lengthsArr = [], productLength = 1) {
    return this.amountOfProductsAndJoints(lengthsArr, productLength).products;
  },

  amountOfJoints(lengthsArr = [], productLength = 1) {
    return this.amountOfProductsAndJoints(lengthsArr, productLength).products;
  },
  
  amountOfAccessories(lengthsArr = [], productLength = 1, indent = 0, interval) {
    let total = 0;
    
    lengthsArr.forEach((distance) => {
      if (!isNaN(Number(distance))) {
        // Calculate total products for the current distance
        let productCount;
        let length = Math.floor(Number(distance) / productLength) * productLength;
        let lengthDiff = distance - length;
        if (lengthDiff > 0.5) {
          productCount = Math.floor(Number(distance) / productLength) + 1;
        } else {
          productCount = Math.floor(Number(distance) / productLength);
        }

        // Calculate total length for current product count
        let totalLength = productCount * productLength;

        if (!isNaN(Number(totalLength))) {
          let length = Math.ceil(Number(totalLength) / productLength) * productLength;
          const buffer = this.safetySystem == 'SAFE' ? 0.3 : 0.4
          length = length.toFixed(2) - buffer; //Ny beräkningsmodell från Per 20240924
          let divideBy = interval > 0 ? interval : productLength;
          let products = ((length - indent) / divideBy) +1
          let productsToFixed = products.toFixed(2) //Avrundar till 2 decimaler för att få det rätt vid Math.ceil 
          total += Math.ceil(productsToFixed);
        }
      }
    });
    
    return total;
  },

  bigpack(product, amount) {
    return [amount % product.bundle, Math.floor(amount / product.bundle)];
  }
}
}
